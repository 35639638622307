/*
* 업무구분: 고객
* 화 면 명: MSPFS106P
* 화면설명: 마케팅동의_P
* 작 성 일: 2023.02.13
* 작 성 자: 이지수
* 수정일 : 2024-04-08 강현철 사유 : ASR240301005 / 투자자정보분석결과보고서 기능 개선 및 마케팅 수집기간 변경
* 수정일 : 2024-04-08 강현철 사유 : ASR250201079 / (사랑온) 마케팅동의서 화면 및 양식 수정
*/
<template>
  <mo-modal class="fts-modal large" ref="modal" title="마케팅동의">
    <template>
      <div class="wrap-modalcontents">
        <h3>마케팅 동의 연락방식 선택</h3>
        <div class="wrap-table mt-3">
          <table class="table col-type">
            <!-- ASR250201079 마케팅동의서 화면 및 양식 수정 추가 2025.04.08 -->
            <tbody>
              <tr>
                <th>
                  <span> 제공동의서 유형 </span>
                </th>
                <td>
                  <mo-radio-wrapper :items="form_type" v-model="rpt_gubun" class="row" />
                </td>
              </tr>
              <tr>
                <th>
                  <span> 연락방식 </span>
                </th>
                <td>
                  <div class="flex-sb">
                    <div class="wrap-input row">
                      <mo-checkbox v-model="Chk_mkt_all" class="row" @click="fn_onClickMktAll(Chk_mkt_all)"> 전체 </mo-checkbox>
                    </div>
                    <div class="wrap-input wrap-switch row">
                      <div class="row">
                        <span class="title-switch"> 전화 </span>
                        <mo-switch v-model="tel_yn" @input="fn_changeCobMktCnvs" class="switch-t1" />
                      </div>
                      <div class="row">
                        <span class="title-switch"> 문자 </span>
                        <mo-switch v-model="sms_yn" @input="fn_changeCobMktCnvs" class="switch-t1" />
                      </div>
                      <div class="row">
                        <span class="title-switch"> E-mail </span>
                        <mo-switch v-model="email_yn" @input="fn_changeCobMktCnvs" class="switch-t1" />
                      </div>
                      <div class="row">
                        <span class="title-switch"> 우편 </span>
                        <mo-switch v-model="pst_yn" @input="fn_changeCobMktCnvs" class="switch-t1" />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 거래여부 </span>
                </th>
                <td>
                  <mo-radio-wrapper :items="Rad_mkt_cust_deal_yn" v-model="deal_yn" class="row" />
                </td>
              </tr>
              <tr>
                <th>
                  <span> 유효기간 </span>
                </th>
                <td>
                  <!-- DEFAULT : 거래 고객의 경우 -->
                  <mo-radio-wrapper :items="dsAvlPrd1" v-model="set_avl_prd" class="row" v-if="deal_yn == 'Y'" />
                  <!-- 미거래 고객의 경우 -->
                  <mo-radio-wrapper :items="dsAvlPrd2" v-model="set_avl_prd" class="row" v-if="deal_yn == 'N'" />
                </td>
              </tr>
              <tr>
                <th>
                  <span> 종료일자 </span>
                </th>
                <td>
                  <!-- mo-date-picker에 class="w130" 추가 및 disabled속성 추가 2023-04-05 jy -->
                  <mo-date-picker v-model="Msk_endDt" :bottom="false" class="w130" @input="fn_changeRadAvlPrd" disabled />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button size="large" @click="fn_Close">닫기</mo-button>
        <mo-button primary size="large" @click="fn_clickYes">확인</mo-button>
      </div>
    </template>
  </mo-modal>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ***********************************************************************************/
import moment from 'moment'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역
   ***********************************************************************************/
  name: 'MSPFS106P',
  screenId: 'MSPFS106P',
  components: {},
  props: {
    popupObj: { type: Object, default: null }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
   ***********************************************************************************/
  created() {
    this.fn_init()
  },
  mounted() {
    this.$bizUtil.insSrnLog('MSPFS106P')
  },
  /***********************************************************************************
   * 화면변수 선언 영역
   ***********************************************************************************/
  data() {
    return {
      /***********************************************************************************
       * AS-IS 전역 변수
       ***********************************************************************************/
      Chk_mkt_all: false, // 전체D
      tel_yn: false, // 마케팅권유방법전화여부
      sms_yn: false, // 마케팅권유방법SMS여부
      email_yn: false, // 마케팅권유방법이메일여부
      pst_yn: false, // 마케팅권유방법우편여부
      deal_yn: false, // 거래여부
      rpt_gubun: '2',   // 제공동의서 유형 ASR250201079 마케팅동의서 화면 및 양식 수정 추가 2025.04.08
      Msk_endDt: '', // 종료일자
      set_avl_prd: '1', // 거래여부에 따른 유효기간 설정
      mkt_year: '', // ㅁㅏ케팅 동의 기한
      valueYear: '' // 마케팅 동의 기한 반영 날짜
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역
   ***********************************************************************************/
  computed: {
    modal() {
      return this.$refs.modal
    },
    dsAvlPrd1() {
      // test
      let rtn = []
      rtn.push({ value: '5', text: '5년', disabled: true })
      rtn.push({ value: '4', text: '4년', disabled: true })
      rtn.push({ value: '3', text: '3년', disabled: true })
      rtn.push({ value: '2', text: '2년', disabled: true })
      rtn.push({ value: '1', text: '1년' })
      return rtn
    },
    dsAvlPrd2() {
      let rtn = []
      rtn.push({ value: '2', text: '2년', disabled: true })
      rtn.push({ value: '1', text: '1년' })
      return rtn
    },
    Rad_mkt_cust_deal_yn() {
      let sltVal = []
      sltVal.push({ value: 'Y', text: '거래고객' })
      sltVal.push({ value: 'N', text: '미거래고객' })
      return sltVal
    },
    // ASR250201079 마케팅동의서 화면 및 양식 수정 추가 2025.04.08
    form_type() {
      let sformType = []
      sformType.push({ value: '2', text: '요약동의서' })
      sformType.push({ value: '1', text: '상세동의서' })
      return sformType
    },
    fn_changeRadAvlPrd() {
      if (this.set_avl_prd !== '') {
        return (this.Msk_endDt = moment(new Date())
          .add(this.set_avl_prd * 12, 'months')
          .add(-1, 'days')
          .format('yyyy-MM-DD')
          .toString())
      } else {
        return (this.Msk_endDt = '')
      }
    }

    // Cob_mkt_cnvs_mth_email_yn() {
    //   let sltVal = [];
    //   sltVal.push({value: 'Y', text: '동의'});
    //   sltVal.push({value: 'N', text: '거절'});
    //   return sltVal;
    // },
    // Cob_mkt_cnvs_mth_pst_yn() {
    //   let sltVal = [];
    //   sltVal.push({value: 'Y', text: '동의'});
    //   sltVal.push({value: 'N', text: '거절'});
    //   return sltVal;
    // },
    // Cob_mkt_cnvs_mth_sms_yn() {
    //   let sltVal = [];
    //   sltVal.push({value: 'Y', text: '동의'});
    //   sltVal.push({value: 'N', text: '거절'});
    //   return sltVal;
    // },
    // Cob_mkt_cnvs_mth_tel_yn() {
    //   let sltVal = [];
    //   sltVal.push({value: 'Y', text: '동의'});
    //   sltVal.push({value: 'N', text: '거절'});
    //   return sltVal;
    // },
  },
  /***********************************************************************************
   * watch 함수 정의 영역
   ***********************************************************************************/
  watch: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역
   ***********************************************************************************/
  methods: {
    /***************************************************************************************
     * Function명 : fn_init
     * 설명       : 변수 초기화
     *****************************************************************************************/
    fn_init() {
      this.Chk_mkt_all = false // 전체D
      this.tel_yn = false // 마케팅권유방법전화여부
      this.sms_yn = false // 마케팅권유방법SMS여부
      this.email_yn = false // 마케팅권유방법이메일여부
      this.pst_yn = false // 마케팅권유방법우편여부
      this.deal_yn = 'Y' // 거래여부
      this.rpt_gubun = '2' // 제공동의서 유형 ASR250201079 마케팅동의서 화면 및 양식 수정 추가 2025.04.08
      this.Msk_endDt = moment(new Date())
        .add(this.set_avl_prd * 12, 'months')
        .add(-1, 'days')
        .format('yyyy-MM-DD')
        .toString() // 종료일자
      this.set_avl_prd = '1' // 거래여부에 따른 유효기간 설정
      this.mkt_year = '' // ㅁㅏ케팅 동의 기한
      this.valueYear = '' // 마케팅 동의 기한 반영 날짜
    },
    /***************************************************************************************
     * Function명 : fn_onClickMktAll
     * 설명       : 마케팅 전체 동의 클릭 이벤트
     *****************************************************************************************/
    fn_endDtOnChange() {
      console.log('종료일자 변경시 ', this.Msk_endDt)
    },
    /***************************************************************************************
     * Function명 : fn_onClickMktAll
     * 설명       : 마케팅 전체 동의 클릭 이벤트
     *****************************************************************************************/
    fn_onClickMktAll() {
      console.log('마케팅전체동의 클릭 value ', this.Chk_mkt_all)
      if (!this.Chk_mkt_all) {
        this.tel_yn = true // 마케팅권유방법전화여부
        this.sms_yn = true // 마케팅권유방법SMS여부
        this.email_yn = true // 마케팅권유방법이메일여부
        this.pst_yn = true // 마케팅권유방법우편여부
      } else {
        this.tel_yn = false // 마케팅권유방법전화여부
        this.sms_yn = false // 마케팅권유방법SMS여부
        this.email_yn = false // 마케팅권유방법이메일여부
        this.pst_yn = false // 마케팅권유방법우편여부
      }
    },

    /**********************************************************************************
     * Function명 : fn_changeCobMktCnvs
     * 설명       : 마케팅 수신 여부에 따른 전체 동의 change
     **********************************************************************************/
    fn_changeCobMktCnvs() {
      if (!this.tel_yn || !this.sms_yn || !this.email_yn || !this.pst_yn) {
        // 한 항목이라도 false일 경우
        this.Chk_mkt_all = false
      }
      if (this.tel_yn && this.sms_yn && this.email_yn && this.pst_yn) {
        // 전체 동의일 경우
        this.Chk_mkt_all = true
      }
    },

    /**********************************************************************************
     * Function명 : fn_setAvlPrd
     * 설명       : 거래여부 change - 거래여부에 따른 유효기간 설정
     **********************************************************************************/
    /* fn_setAvlPrd (val) {
      if (val === 'Y') {
        this.set_avl_prd = '1' // 거래여부에 따른 유효기간 설정
        // this.Msk_endDt = '' // 종료일자
      } else {
        this.set_avl_prd = '' // 거래여부에 따른 유효기간 설정
        this.Msk_endDt = '' // 종료일자
      }
    }, */

    /**********************************************************************************
     * Function명 : fn_changeRadAvlPrd
     * 설명       : 유효기간 change(현재 날짜에서 유효기간 만큼 더한 후 하루를 뺀다.)
     **********************************************************************************/
    /* fn_changeRadAvlPrd (val) {
      // var calcDate
      // this.Msk_endDt = moment(new Date()).add(val*12,'months').format('yyyy-MM-DD').toString();
      this.Msk_endDt = moment(new Date()).add(val * 12, 'months').add(-1, 'days').format('yyyy-MM-DD').toString()
    }, */

    /**********************************************************************************
     * Function명 : fn_clickYes
     * 설명       : 확인 클릭
     **********************************************************************************/
    fn_clickYes() {
      if (!this.tel_yn && !this.sms_yn && !this.email_yn && !this.pst_yn) {
        this.fn_AlertPopup('연락방식을 하나이상 선택하세요.')
        return
      }
      if (this.deal_yn === '') {
        this.fn_AlertPopup('거래여부를 선택하세요.')
        return
      }
      if (this.set_avl_prd === '') {
        this.fn_AlertPopup('유효기간을 선택하세요.')
        return
      }

      // 0 신규등록구분 (유포털)
      // 1 전화, 2 문자메세지 3 이메일 4 우편
      // 5 거래고객여부
      // 6 유효기간
      // 7 종료일자
      var objRtn = {
        vNewYn: 'NEW',
        tel_yn: this.tel_yn,
        sms_yn: this.sms_yn,
        email_yn: this.email_yn,
        pst_yn: this.pst_yn,
        deal_yn: this.deal_yn,
        avl_prd: this.set_avl_prd,
        end_dt: this.Msk_endDt,
        rpt_gubun: this.rpt_gubun // ASR250201079 마케팅동의서 화면 및 양식 수정 추가 2025.04.08
      }
      this.fn_Close(objRtn)
    },

    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/
    fn_AlertPopup(msg) {
      let t_popupObj = {
        confirm: false,
        content: msg
      }
      this.$emit('fs-alert-popup', 0, t_popupObj)
    },
    /******************************************************************************
     * Function명 : fn_Open
     * 설명       : modal open
     ******************************************************************************/
    fn_Open() {
      this.modal.open()
    },

    /******************************************************************************
     * Function명 : fn_Close
     * 설명       : modal close
     ******************************************************************************/
    fn_Close(objRtn) {
      if (objRtn.vNewYn !== 'NEW') {
        // 닫기 버튼 클릭 시
        objRtn = { vNewYn: 'N' }
      }
      this.$emit('fs-popup-callback', 4, objRtn)
      this.fn_init()
      this.modal.close()
    }
  }
}
</script>
<style scoped></style>
